@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
  background-image: url("bg6.jpeg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}

main {
  /* padding: 20px; */
  background-color: #f0f0f0;
}

.css-bql6zh-MuiInputBase-root-MuiOutlinedInput-root,
.css-eetn63-MuiFormControl-root-MuiTextField-root {
  border-radius: 25px !important;
}
