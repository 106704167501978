@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif !important;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Modify the vertical scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 10px; /* Width of the vertical scrollbar */
}

::-webkit-scrollbar-track {
  background-color: #ebeff361;
  /* Track color */
}

::-webkit-scrollbar-thumb {
  background-color: #4379f2; /* Thumb color */
  /* border-radius: 10px;  */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  max-height: 10px;
}

/* Hover effect for the vertical scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #4379f2;
  /* cursor: all-scroll; */
}
